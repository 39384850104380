// Packages:
import React, { useState, useRef, useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import generateREMSize from '../utils/generateREMSize'
import { Helmet } from 'react-helmet'
import { Scrollbars } from 'react-custom-scrollbars-2'


// Typescript:
import { IReduxState } from '../redux/state/types'


// Constants:
import { COLOR, THEME } from '../constants'
import SECONDARY_COLORS from '../styles/colors'
import { LOCAL_STORAGE_KEY } from '../constants/localStorage'


// Components:
import Navbar from './Navbar'
import Footer from './Footer'


// Redux:
import { toggleTheme } from '../redux/actions'


// Styles:
const GlobalStyle = createGlobalStyle<{
  currentTheme: THEME
  isThemeMounted: boolean
}>`
  body {
    margin: 0;
    overflow-x: hidden;
    background-color: ${ props => props.currentTheme === THEME.DARK ? COLOR.BLACK : COLOR.WHITE };
    color: ${ props => props.currentTheme === THEME.DARK ? COLOR.WHITE : COLOR.BLACK };
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: ${ props => props.isThemeMounted ? 'background-color 0.25s ease,' : '' } color 0.25s ease;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  ::selection {
    background: ${ props => SECONDARY_COLORS.HIGHLIGHT[ props.currentTheme ] };
  }
`


// Functions:
const Layout = ({ children }) => {
  // Constants:
  const dispatch = useDispatch()
  const isomorphicWindow = typeof window !== 'undefined' && window
  const ResizeObserver = (isomorphicWindow as typeof window).ResizeObserver

  // State:
  const theme = useSelector((state: IReduxState) => state.theme)
  const [ isThemeMounted, setIsThemeMounted ] = useState(false)
  const [ fontSize, setFontSize ] = useState(1)

  // Ref:
  const observer = ResizeObserver ? useRef(
    new ResizeObserver(entries => {
      const { width } = entries[0].contentRect
      if (width > 666) setFontSize(generateREMSize(width))
      else setFontSize(1)
    })
  ) : useRef()

  // Effects:
  useEffect(() => {
    (async () => {
      const localTheme = (isomorphicWindow as typeof window).localStorage?.getItem(LOCAL_STORAGE_KEY.THEME) as THEME | null
      if (localTheme && localTheme !== THEME.LIGHT) dispatch(toggleTheme())
      await new Promise(resolve => setTimeout(resolve, 250))
      setIsThemeMounted(true)
    })()
  }, [])

  useEffect(() => {
    (isomorphicWindow as typeof window).localStorage?.setItem(LOCAL_STORAGE_KEY.THEME, theme)
  }, [ theme ])

  useEffect(() => {
    if (observer.current) observer.current.observe((isomorphicWindow as typeof window).document.body)
    return () => {
      if (observer.current) observer.current.unobserve((isomorphicWindow as typeof window).document.body)
    }
  }, [ observer ])

  // Return:
  return (
    <React.StrictMode>
      <Helmet title={ 'Dirag Biswas' } titleTemplate={ '%s · Creator' }>
        <meta name='description' content={ `Hi, I'm Dirag Biswas. I'm a Software Engineer, Designer and YouTuber. 👋` } />
        <meta name='image' content={ 'https://diragb.dev/static/dirag-biswas-square-cover.jpg' } />
        <meta property='og:url' content={ 'https://diragb.dev' } />
        <meta property='og:type' content='article' />
        <meta property='og:title' content={ 'Dirag Biswas' } />
        <meta property='og:description' content={ `Hi, I'm Dirag Biswas. I'm a Software Engineer, Designer and YouTuber. 👋` } />
        <meta property='og:image' content={ 'https://diragb.dev/static/dirag-biswas-square-cover.jpg' } />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:creator' content={ 'diragb' } />
        <meta name='twitter:title' content={ 'Dirag Biswas' } />
        <meta name='twitter:description' content={ `Hi, I'm Dirag Biswas. I'm a Software Engineer, Designer and YouTuber. 👋` } />
        <meta name='twitter:image' content={ 'https://diragb.dev/static/dirag-biswas-rect-cover.jpg' } />
        <meta name='twitter:image:alt' content={ 'Dirag Biswas: Developer. Designer. YouTuber.' } />
        <meta name='theme-color' content={ SECONDARY_COLORS.HIGHLIGHT.DARK } />
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin='anonymous' />
        <link href='https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap' rel='stylesheet' />
      </Helmet>
      <GlobalStyle currentTheme={ theme } isThemeMounted={ isThemeMounted } />
      <Scrollbars style={{ width: '100vw', height: '100vh' }} universal={ true }>
        <span style={{ fontSize: `${ fontSize }rem` }}>
          <Navbar />
          { children }
          <Footer />
        </span>
      </Scrollbars>
    </React.StrictMode>
  )
}


// Exports:
export default Layout
