// Typescript:
import { IReduxAction } from './types'


// Constants:
import initialState from '../state'
import ACTION_TYPES from '../action-types'
import { THEME } from '../../constants'


// Functions:
const rootReducer = (state = initialState, action: IReduxAction) => {
  switch (action.type) {
    case ACTION_TYPES.TOGGLE_THEME:
      return {
        ...state,
        theme: action.payload ?? state.theme === THEME.LIGHT ? THEME.DARK : THEME.LIGHT
      }
    default:
      return state
  }
}


// Exports:
export default rootReducer
