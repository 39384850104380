// Typescript:
import { THEME } from '../constants'


// Constants:
import ACTION_TYPES from './action-types'


// Exports:
export const toggleTheme = (payload?: THEME) => ({ type: ACTION_TYPES.TOGGLE_THEME, payload })
