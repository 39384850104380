// Packages:
import React from 'react'


// Functions:
const Footer = () => {
  return (
    <div>
    </div>
  )
}


// Exports:
export default Footer
