// Constants:
import { THEME } from '../constants'


// Exports:
export const LANGUAGE_COLOR = {
  FIREBASE: '#FFC928',
  PYTHON: '#4B8BBE',
  REDUX: '#7849BD',
  TYPESCRIPT: '#2D79C7',
  SOLID: '#446B9E',
  REACT: '#61DBFB',
}

export default {
  HIGHLIGHT: {
    [ THEME.LIGHT ]: '#79A9D1',
    [ THEME.DARK ]: '#28AFB0'
  },
  FLASHY_COLORS: [ '#FFE74C', '#FF5964', '#1481BA', '#60A561', '#FFB563', '#B48EAE' ]
}
