// Exports:
export enum THEME {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

export enum COLOR {
  BLACK = '#111111',
  WHITE = '#F9FAFC',
  CELADON = '#AFD5AA'
}

export const ROUTE = {
  INDEX: {
    _id: 'home',
    path: '/',
    title: 'Home'
  },
  CONTACT: {
    _id: 'contact',
    path: '/contact',
    title: 'Contact'
  },
  BLOG: {
    _id: 'blog',
    path: '/blog',
    title: 'Blog'
  },
  RESUME: {
    _id: 'resume',
    path: '/resume',
    title: 'Resume'
  },
}