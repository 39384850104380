// Packages:
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Color from 'color'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'gatsby'
import urlencode from 'urlencode'


// Typescript:
import { IReduxState } from '../redux/state/types'


// Imports:
import { RiMenu2Fill, RiSunLine, RiMoonLine } from 'react-icons/ri'


// Constants:
import { COLOR, ROUTE, THEME } from '../constants'


// Redux:
import { toggleTheme } from '../redux/actions'


// Styles:
const Wrapper = styled.div<{ isSmall: boolean, currentTheme: THEME }>`
  position: ${ props => props.isSmall ? 'fixed' : 'absolute' };
  top: 0;
  z-index: ${ props => props.isSmall ? '3' : 'inherit' };
  display: flex;
  justify-content: ${ props => props.isSmall ? 'space-between' : 'center' };
  align-items: center;
  width: ${ props => props.isSmall ? 'calc(100% - 2rem)' : '100vw' };
  height: ${ props => props.isSmall ? '4.5em' : '5em' };;
  margin-top: ${ props => props.isSmall ? '0em' : '1em' };
  padding: ${ props => props.isSmall ? '0 1em' : 'inherit' };
  background-color: ${
    props => props.isSmall ?
      props => props.currentTheme === THEME.DARK ? COLOR.BLACK : COLOR.WHITE
    :
      'transparent'
  };
`

const Center = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 50em;
  user-select: none;
`

const NavButtons = styled.div`
  display: flex;
`

const NavButton = styled(Link)<{ currenttheme: THEME, active: string }>`
  padding: 0.5em 0.75em;
  border-radius: 7px;
  color: inherit;
  text-decoration: none;
  font-size: 1em;
  font-weight: ${ props => props.active === 'true' ? 600 : 400 };
  color: ${ props => props.active === 'true' ?
    'inherit'
  :
    props.currenttheme === THEME.DARK ?
      Color(COLOR.WHITE).desaturate(1).darken(0.5).toString()
    :
      Color(COLOR.WHITE).desaturate(1).darken(0.75).toString()
  };
  cursor: pointer;
  user-select: none;
  transition: background-color 0.25s ease;

  &:hover {
    background-color: ${ props => props.currenttheme === THEME.DARK ? Color(COLOR.WHITE).alpha(0.075).toString() : Color(COLOR.BLACK).alpha(0.075).toString() };
  }
`

const ThemeButton = styled.span`
  width: 1em;
  height: 1em;
  padding: 0.5em;
  cursor: pointer;
`

const Sidebar = styled.div<{ isVisible: boolean, currentTheme: THEME }>`
  position: fixed;
  left: ${ props => props.isVisible ? '0' : '-75%' };
  z-index: 4;
  width: 75%;
  height: 100%;
  background-color: ${ props => props.currentTheme === THEME.DARK ? COLOR.BLACK : COLOR.WHITE };
  transition: all 0.25s ease;
`

const SidebarDarkness = styled.div<{ isSet: boolean }>`
  position: fixed;
  left: ${ props => props.isSet ? '0' : '-100vw' };
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  filter: ${ props => props.isSet ? 'opacity(1)' : 'opacity(0)' };
  transition: ${ props => props.isSet ? 'filter 0.25s ease' : 'filter 0.25s ease, left 0.01s ease 0.25s' };
`


// Functions:
const Navbar = () => {
  // Constants:
  const dispatch = useDispatch()
  const isomorphicWindow = typeof window !== 'undefined' && window
  const ResizeObserver = (isomorphicWindow as typeof window).ResizeObserver

  // State:
  const theme = useSelector((state: IReduxState) => state.theme)
  const [ isSmall, setIsSmall ] = useState(false)
  const [ isSidebarVisible, setIsSidebarVisible ] = useState(false)

  // Ref:
  const observer = ResizeObserver ? useRef(
    new ResizeObserver(entries => {
      const { width } = entries[0].contentRect
      if (width <= 666) setIsSmall(true)
      else setIsSmall(false)
    })
  ) : useRef()

  // Effects:
  useEffect(() => {
    if (observer.current) observer.current.observe((isomorphicWindow as typeof window).document.body)
    return () => {
      if (observer.current) observer.current.unobserve((isomorphicWindow as typeof window).document.body)
    }
  }, [ observer ])

  // Return:
  return (
    <>
      <Wrapper isSmall={ isSmall } currentTheme={ theme }>
        {
          isSmall ?
          <>
            <RiMenu2Fill onClick={ () => setIsSidebarVisible(!isSidebarVisible) } />
            <ThemeButton onClick={ () => dispatch(toggleTheme()) }>
              { theme === THEME.DARK ? <RiSunLine /> : <RiMoonLine /> }
            </ThemeButton>
          </>
          :
          <Center>
            <NavButtons>
              {
                Object.values(ROUTE).map(route =>
                  <NavButton
                    key={ route._id }
                    to={ route.path }
                    currenttheme={ theme }
                    active={ (urlencode.decode(route.path) === urlencode.decode((isomorphicWindow as typeof window).location?.pathname)) + '' }
                  >{ route.title }</NavButton>
                )
              }
            </NavButtons>
            <ThemeButton onClick={ () => dispatch(toggleTheme()) }>
              { theme === THEME.DARK ? <RiSunLine /> : <RiMoonLine /> }
            </ThemeButton>
          </Center>
        }
      </Wrapper>
      {
        isSmall &&
        <>
          <SidebarDarkness
            isSet={ isSidebarVisible }
            onClick={ () => setIsSidebarVisible(!isSidebarVisible) }
          />
          <Sidebar
            isVisible={ isSidebarVisible }
            currentTheme={ theme }
          >
            <RiMenu2Fill style={{ paddingLeft: '1rem', paddingTop: '1.75rem' }} onClick={ () => setIsSidebarVisible(!isSidebarVisible) } />
            <NavButtons style={{ marginTop: '1rem', marginLeft: '0.25rem', flexDirection: 'column' }}>
              {
                Object.values(ROUTE).map(route =>
                  <NavButton
                    key={ route._id }
                    to={ route.path }
                    currenttheme={ theme }
                    active={ (urlencode.decode(route.path) === urlencode.decode((isomorphicWindow as typeof window).location?.pathname)) + '' }
                    style={{
                      width: 'fit-content',
                      backgroundColor: 'transparent'
                    }}
                  >{ route.title }</NavButton>
                )
              }
            </NavButtons>
          </Sidebar>
        </>
      }
    </>
  )
}


// Exports:
export default Navbar
