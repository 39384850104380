// Packages:
import { createStore } from 'redux'
import rootReducer from './reducers'


// Typescript:
import { IReduxState } from './state/types'


// Exports:
export default (preloadedState: IReduxState) => createStore(rootReducer, preloadedState)
