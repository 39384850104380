// Typescript:
import { IReduxState } from './types'


// Constants:
import { THEME } from '../../constants'


// Constants:
const initialState : IReduxState = {
  theme: THEME.LIGHT
}


// Exports:
export default initialState
